<template>
  <div class="Product-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar
          v-if="Product"
          :title="`Sale By Product`"
          :start="start"
          :end="end"
        />

        <v-container fluid>
          <table class="center">
            <tbody class="report-container">
              <v-card-text>
                <v-data-table
                  dense
                  readonly
                  :sort-by="sortBy"
                  group-by.sync="Category.name"
                  :headers="headers"
                  :itemsPerPage="Product.length"
                  hide-default-footer
                  :items="Product"
                >
                  <template v-slot:item="{ item }">
                    <tr v-if="item.totalPrice - item.totalCost !== 0">
                      <td class="text-left text-capitalize">
                        {{ item.name }}
                      </td>
                      <td class="text-right">
                        {{ item.totalPrice | currency }}
                      </td>
                      <td class="text-right">
                        {{ item.totalCost | currency }}
                      </td>
                      <td class="text-right" :style="{ color: color(item) }">
                        {{ (item.totalPrice - item.totalCost) | currency }}
                      </td>
                    </tr>
                  </template>
                  <template
                    v-slot:body.append="{ headers }"
                    v-if="Product.length > 0"
                  >
                    <tr
                      :style="{ 'background-color': $style.listTotal.filter }"
                    >
                      <td
                        :colspan="headers.length - 3"
                        class="text-right font-weight-bold"
                      >
                        Total
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ totalPrice | currency }}
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ totalCost | currency }}
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ totalProfit | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
// import CategoryService from "@/modules/Category/service";
import saleService from "../../service";
import { mapGetters, mapActions } from "vuex";
// import Hashids from "hashids";
// const hashids = new Hashids();

export default {
  data() {
    return {
      start: null,
      end: null,
      isLoading: true,
      Product: [],
      sortBy: "name",
      headers: [
        {
          text: "NAME",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "SALE",
          align: "right",
          value: "totalPrice",
          sortable: true,
        },
        {
          text: "COST",
          align: "right",
          value: "totalCost",
          sortable: true,
        },
        {
          text: "PROFIT",
          align: "right",
          value: "totalProfit",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    totalProfit() {
      return this.totalPrice - this.totalCost;
    },
    totalPrice() {
      let total = 0;
      this.Product.map((row) => {
        total += row.totalPrice;
      });
      return total;
    },
    totalCost() {
      let total = 0;
      this.Product.map((row) => {
        total += row.totalCost;
      });
      return total;
    },
  },
  async created() {
    if (!this.currentBusiness) this.initUser();
    this.start = this.$route.query.start;
    this.end = this.$route.query.end;
    await this.getProduct();
  },
  components: { ReportViewBar },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    color(item) {
      let value = "black";
      let profit = item.totalPrice - item.totalCost;
      if (profit < 0) {
        value = "red";
      }
      return value;
    },
    getProduct() {
      let where = {
        start: this.start,
        end: this.end,
      };

      if (this.$route.query.CategoryId) {
        where.CategoryId = this.$route.query.CategoryId;
      }

      // console.log("where", where);

      return saleService.getProduct(where).then((response) => {
        // console.log("response category", response);
        this.$Progress.finish();
        this.Product = response.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
